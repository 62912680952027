import { MouseEvent } from "react";

import { useBotOptions } from "../../Context/BotOptions";

import "./ChatBotHeader.css";
import ServiceAgent from '../../assets/customer-service-agent.png';
import closeChatIcon from "../../assets/close_chat_icon.svg";

const ChatBotHeader = () => {
  // handles options for bot
  const { botOptions, setBotOptions,setCall } = useBotOptions();

  // styles for header
  const headerStyle: React.CSSProperties = {
    background: `linear-gradient(to right, ${botOptions.theme?.secondaryColor},
			${botOptions.theme?.primaryColor})`,
    ...botOptions.headerStyle,
  };

  // icons in header
  const headerImages = {
    headerAvatar: {
      backgroundImage: `url(${ServiceAgent})`,
    },
    notificationIcon: {
      backgroundImage: `url(${botOptions.notification?.icon})`,
    },
    audioIcon: {
      backgroundImage: `url(${botOptions.audio?.icon})`,
    },
    closeChatIcon: {
      backgroundImage: `url(${closeChatIcon})`,
      top:'10px'

    },
  };

  /**
   * Handles closing of chat window.
   */
  const handleCloseChat = () => {
	setCall(false)
    // setBotOptions({ ...botOptions, isOpen: false });
  };

  return (
    <div style={headerStyle} className="rcb-chat-header-container">
      <div className="rcb-chat-header">
        {botOptions.botBubble?.showAvatar && (
          <div style={headerImages.headerAvatar} className="rcb-bot-avatar" />
        )}
        {botOptions.header?.title}
      </div>
      <div className="rcb-chat-header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 5,
          }}
        >
          <div style={{ cursor: "pointer", display: "flex" }}>
            <span style={{ margin: 0, fontSize: 20, fontWeight: "bold" }}>
              CCX Customer Support
            </span>
          </div>
          <span
            className="material-symbols-outlined more-vert"
            style={{ fontSize: "1.5rem", cursor: "pointer", paddingLeft: 10 }}
          >
            more_vert
          </span>
          {/* <div className="dropdown-options" ref={dropdownOption}>
              <a href="#" onClick={endChat}>End Chat</a>
            </div> */}
        </div>
        <div
          style={headerImages.closeChatIcon}
          onMouseDown={(event: MouseEvent) => {
            event.stopPropagation();
            handleCloseChat();
          }}
          className="rcb-close-chat-icon"
        ></div>
      </div>
    </div>
  );
};

export default ChatBotHeader;
